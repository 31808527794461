import React, { useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  ImageField,
  useNotify,
  useRedirect,
  useDataProvider,
  required,
  useRecordContext,
} from "react-admin";
import { useParams } from "react-router-dom";
import {
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons,
} from "ra-input-rich-text";

export const ProductEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const record = useRecordContext();

  const { id } = useParams();
  const productId = props.id || props.record?.id || id;

  const [files, setFiles] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  // const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(true);

  // Обработка изменения файлов
  const handleImageChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles([...files, ...selectedFiles]); // Добавляем новые файлы к уже выбранным
  };

  // Удаление файла по индексу
  const handleDeleteLocalImage = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  // Функция для удаления изображения с сервера и базы данных
  const handleDeleteImage = async (image) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/products/${productId}/images/${image}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        // Успешное удаление с сервера, обновляем состояние
        const updatedImages = existingImages.filter((img) => img !== image);
        setExistingImages(updatedImages);
        notify("Изображение удалено", { type: "success" });
      } else {
        throw new Error("Ошибка при удалении изображения");
      }
    } catch (error) {
      notify(`Ошибка при удалении изображения: ${error.message}`, {
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (productId) {
      setLoading(true);
      dataProvider
        .getOne("products", { id: productId })
        .then(({ data }) => {
          console.log("ProductEdit Received data:", data);

          if (Array.isArray(data.images) && data.images.length > 0) {
            setExistingImages([...data.images]);
          } else {
            setExistingImages([]);
          }

          // setRecord(data);
          setLoading(false);
        })
        .catch((error) => {
          notify(`Ошибка при загрузке продукта: ${error.message}`, {
            type: "error",
          });
          setLoading(false);
        });
    } else {
      console.log("ProductEdit productId is undefined");
      setLoading(false);
    }
  }, [dataProvider, productId, notify]);

  const handleSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("long_name", data.long_name);
    formData.append("description", data.description);
    formData.append("rating", data.rating || "");
    formData.append("old_price", data.old_price || "");
    formData.append("new_price", data.new_price);
    formData.append("availability", data.availability);
    formData.append("seller", data.seller);
    formData.append("visible", data.visible);
    formData.append("sort_order", data.sort_order);

    for (let i = 0; i < files.length; i++) {
      formData.append("images", files[i]);
    }

    fetch(`${process.env.REACT_APP_API_URL}/products/${productId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          notify("Товар успешно обновлен", { type: "success" });
          redirect("list", "products");
        } else {
          throw new Error("Ошибка при обновлении товара");
        }
      })
      .catch((error) => {
        notify(`Ошибка при обновлении товара: ${error.message}`, {
          type: "error",
        });
      });
  };

  if (loading) {
    return <div>Загрузка...</div>;
  }

  return (
    <Edit {...props}>
      <SimpleForm onSubmit={handleSubmit} alwaysEnableSave>
        <span>ID: {id}</span>
        {/* <NumberInput source="id" label="ID" /> */}
        <NumberInput source="sort_order" label="Сортировка" />

        <TextInput source="name" label="Название" validate={[required()]} />

        <TextInput
          source="long_name"
          label="Длинное название"
          validate={[required()]}
        />

        <RichTextInput
          source="description"
          label="Описание"
          validate={[required()]}
          fullWidth={true}
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect size={"large"} />
              <FormatButtons size={"large"} />
              <ListButtons size={"large"} />
              <LinkButtons size={"large"} />
              <QuoteButtons size={"large"} />
              <ClearButtons size={"large"} />
            </RichTextInputToolbar>
          }
        />

        <NumberInput source="rating" label="Рейтинг" />
        <NumberInput source="old_price" label="Старая цена" />
        <NumberInput
          source="new_price"
          label="Новая цена"
          validate={[required()]}
        />
        <SelectInput
          source="availability"
          choices={[
            { id: "На складе", name: "На складе" },
            { id: "Под заказ", name: "Под заказ" },
          ]}
          label="Доступность"
          validate={[required()]}
          format={(v) => v} // Преобразуем данные из API
          parse={(v) => v} // Преобразуем данные для отправки
        />
        <TextInput source="seller" label="Продавец" validate={[required()]} />

        {/* Отображение существующих изображений */}
        {existingImages.length > 0 && (
          <>
            <h4>Существующие изображения:</h4>
            <div
              style={{
                display: "flex",
                marginBottom: 40,
                flexDirection: "row",
                gap: 20,
                width: "100%",
              }}
            >
              {existingImages.map((image, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 20,
                    justifyContent: "center",
                    alignItems: "center",
                    width: 200,
                    border: "1px solid #808080",
                    borderRadius: 10,
                    padding: 10,
                  }}
                >
                  <a
                   //  href={`${process.env.REACT_APP_ASSETS_URL}/uploads/${image}`} -->
                    href={`https://cdn.pixl.com.ua/shop-cdn/${image}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                   //   src={`${process.env.REACT_APP_ASSETS_URL}/uploads/${image}`} -->
                      src={`https://cdn.pixl.com.ua/shop-cdn/${image}`}
                      title={`Изображение ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </a>

                  <button
                    type="button"
                    onClick={() => handleDeleteImage(image)}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      padding: "5px",
                    }}
                  >
                    Удалить
                  </button>
                </div>
              ))}
            </div>
          </>
        )}

        {/* Поле для выбора изображений */}
        <input
          type="file"
          onChange={handleImageChange}
          multiple
          accept="image/*"
          disabled={files.length === 2}
        />

        {/* Превью выбранных изображений с кнопкой для удаления */}
        {files && (
          <div
            style={{
              display: "flex",
              marginBottom: 40,
              flexDirection: "row",
              gap: 20,
              width: "100%",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {files.map((file, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  border: "1px solid #808080",
                  borderRadius: 10,
                  padding: 10,
                }}
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  style={{
                    width: "100px",
                    height: "100px",
                    marginRight: "10px",
                    objectFit: "cover",
                  }}
                />
                <button
                  type="button"
                  onClick={() => handleDeleteLocalImage(index)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    padding: "5px",
                  }}
                >
                  Удалить
                </button>
              </div>
            ))}
          </div>
        )}
      </SimpleForm>
    </Edit>
  );
};
